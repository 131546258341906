<template>
	<div class="orderDetail">
		<!-- <div class="orderStatus flexcenter">
			<img src="../../public/static/images/success.png" height="36" width="33">
			<span>交易成功</span>
		</div> -->
		<div class="orders" v-if="order.length>0&&order[0].expressnum">
			<div class="orderid">订单号：{{order[0].ordernum}}</div>
			<div class="deliveryId">快递：【{{order[0].express}}】{{order[0].expressnum}}</div>
		</div>
		<div class="orderAddress">
			<div style="width: 48px;" class="flexcenter">
				<img src="../../public/static/images/place.png" width="21" height="25" />
			</div>
			<div style="flex: 1;" v-for="addr in address" key="index">
				<div class="addUser">
					<div style="flex: 1;">收货人：{{addr.username}}</div>
					<div>{{addr.phone}}</div>
				</div>
				<div class="address">收货地址：{{addr.province}}{{addr.city}}{{addr.dist}}{{addr.address}}</div>
			</div>
		</div>
		<div class="orderPro" v-for="(good,idx) in detail" :key="idx" @click="ReBuy(good)">
			<div class="proimg flexcenter">
				<img :src="good.headimg" width="103" height="103px">
			</div>
			<div class="proInfo">
				<div class="pros">
					<div class="proname">
						<div class="name">{{good.title}}</div>
					</div>
					<div class="counts">
						<div class="price" style="float: left;">￥{{good.price}}</div>
						<div>&times;{{good.number}}</div>
					</div>
				</div>
			</div>
			<!-- <div class="frees">合计：￥{{good.totalprice}}（含运费￥0.00）</div> -->
		</div>
		<div class="buttons" v-if="order.length>0">

			<div style="flex: 1;"></div>
			<div class="shh" v-show="order[0].paystatus==2&&order[0].expressnum!=''" @click="ShowExpress(order[0])">查看物流</div>
			<div class="shh" v-show="order[0].paystatus==2" @click="Confirm(order[0])">确认收货</div>
			<div class="wuli" v-show="order[0].paystatus==1">已付款</div>
			<div class="shh" v-show="order[0].paystatus==0" @click="Pay(order[0])">支付</div>
			
			
		</div>

		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ordernum: "",
				detail: [],
				address: [],
				order: []
			}
		},
		mounted() {
			this.ordernum = sessionStorage.getItem("ordernum");
			this.getInfo();
		},
		methods: {
			getInfo() {
				this.$store.dispatch("GET", {
					url: "api/shop/?action=orderdetail&ordernum=" + this.ordernum
				}).then(result => {
					this.address = result.data.data.address;
					this.detail = result.data.data.detail;
					this.order = result.data.data.order;
					console.log(this.order)
				})
			},
			ShowExpress(item) {
			
				window.location.href = "https://m.kuaidi100.com/result.jsp?nu=" + item.expressnum
			},
			Confirm(item) {
			
				this.$store.dispatch("POST", {
					url: "api/wx/?action=paystatus",
					query: {
						id: item.orderid,
						paystatus: 3 //已签收
					}
				}).then(result => {
					this.GetList();
				})
			},
			GoComment(item) {
				sessionStorage.setItem("comment", JSON.stringify(item));
				this.$router.push({
					path: '/comment'
				})
			},
			Pay(item) {
				sessionStorage.setItem("orderid", item.id);
				window.location.href = "/pay/";
			},
			ReBuy(item) {
				this.$router.push({
					path: '/prodetail/' + item.goodid
				})
			},
			
		}
	}
</script>

<style scoped="scoped">
	.orderDetail {
		height: 100%;
		width: 100%;
	}

	.orderStatus {
		height: 97px;
		font-size: 24px;
		color: #41acff;
		background: #f6fafe;
	}

	.orderAddress {
		min-height: 77px;
		margin-top: 10px;
		background: #f6fafe;
		display: flex;
		padding-left: 15px;
		padding-right: 15px;
	}

	.addUser {
		margin-top: 10px;
		height: 28px;
		line-height: 28px;
		font-size: 13px;
		color: #7b7b7b;
		display: flex;
	}

	.address {
		min-height: 28px;
		font-size: 12px;
		line-height: 20px;
		padding: 2px 0 10px 0;
	}

	.proimg {
		width: 103px;
	}

	.orderPro {
		margin-top: 14px;
		height: 118px;
		background: #f6fafe;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
	}

	.proInfo {
		flex: 1;
		margin-left: 15px;
	}

	.pros {
		min-height: 78px;

	}

	.counts {

		font-size: 12px;
		color: #a4a4a4;
		text-align: right;
	}

	.proname {
		min-height: 28px;
		line-height: 28px;
	}

	.name {
		font-size: 13px;
		color: #7b7b7b;
	}

	.price {
		font-size: 15px;
		color: #26a1ff;
	}

	.frees {
		text-align: right;
		font-size: 8px;
		color: #7b7b7b;
		margin-top: 10px;
	}

	.buttons {
		display: flex;
		height: 35px;
		padding-top: 25px;
		padding-right: 20px;
	}

	.wuli {
		margin-left: 10px;
		font-size: 12px;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #ddd;
		border-radius: 15px;
	}

	.shh {
		margin-left: 10px;
		font-size: 12px;
		color: #30a5ff;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #43aeff;
		border-radius: 15px;
	}

	.orders {
		margin-top: 15px;
		padding-left: 20px;
		height: 96px;
		background: #f6fafe;
		font-size: 15px;
		color: #7b7b7b;
	}

	.orderid {
		padding-top: 20px;
	}

	.deliveryId {
		padding-top: 12px;
	}
</style>
