<template>
	<div>
		<div style="padding: 15px; border-bottom: 1px solid #f6f6f6;" v-for="item in DataList" @click="checkItem(item)">
			<div class="checkbox">
				<img v-if="item.ckd" src="../../../public/static/images/checked.png" />
				<img v-else src="../../../public/static/images/nocheck.png" />
			</div>
			<div style="margin-left: 60px;">
				<div style="color: #0C85E1; font-weight: bold;">
					{{item.title}}
				</div>
				<div style="color: #0C85E1; font-weight: bold; padding: 4px 0;">
					￥{{item.price}} <span v-if="item.jifen>0">/ {{item.jifen}} 积分</span>
					<span style="padding-left: 10px; font-size: 12px; color: #888888; text-decoration: line-through;">原价:￥{{item.oldprice}}</span>
					<span style="float: right; font-size: 12px;">有效期：{{times[item.can_use_time]}}</span>
				</div>
				<div style="font-size: 12px;">
					{{item.tc_desc}}
				</div>
			</div>

		</div>

		<div style="height: 100px;"></div>
		<div class="btnJifen" @click="duiHuanNow" v-if="myjifen>=needscore&&needscore>0">立即兑换 <span style="font-size: 11px;">({{myjifen}}积分)</span></div>
		<div class="btnGoumai" @click="buyNow" v-if="myjifen>=needscore&&needscore>0">立即购买</div>
		<div class="btnStarzx" @click="buyNow" v-if="(myjifen<needscore||needscore==0)&&needmoney>0">立即购买</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				myjifen: 0,
				needmoney: 0,
				needscore: 0,
				times: {
					0: "永久",
					86400: "1天",
					604800: "7天",
					864000: "10天",
					2592000: "30天",
					5184000: "60天",
					7776000: "90天",
					10368000: "120天",
					15552000: "180天",
					31536000: "365天",
				},

			}
		},
		mounted() {
			this.getList();
			this.getMe();
		},
		methods: {
			getList() {
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=tclisth5"
				}).then(res => {
					for (let item of res.data.rows.rows) {
						item.ckd = false;
					}
					this.DataList = res.data.rows.rows;
					this.checkItem(this.DataList[0])
				})
			},
			getMe() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=me"
				}).then(result => {
					this.myjifen = parseInt(result.data.data.rows[0].nowscore)
				})
			},
			checkItem(item) {
				this.DataList.map(e => {
					e.ckd = false;
				})
				item.ckd = true;
				this.needscore = parseInt(item.jifen);
				this.needmoney = parseFloat(item.price)
			},
			duiHuanNow() {


				$confirm({
					msg: "确认兑换?",
					okcb: _ => {
						let checkitem = null;
						this.DataList.map(e => {
							if (e.ckd) {
								checkitem = e;
							}
						})
						this.$store.dispatch("GET", {
							url: "/api/zixun/?action=dhzx&tcid=" + checkitem.id
						}).then(result => {
							if (result.data.msg == 'ok') {
								$alert({
									msg: "兑换成功！",
									okcb: _ => {
										window.history.back()
									}
								})
							} else {
								$alert({
									msg: result.data.msg,
									okcb: _ => {

									}
								})
							}

						})
					}
				})



			},
			buyNow() {
				$confirm({
					msg: "确认购买?",
					okcb: _ => {
						let checkitem = null;
						this.DataList.map(e => {
							if (e.ckd) {
								checkitem = e;
							}
						})
						
						console.log(checkitem)
						sessionStorage.setItem("zxorder", checkitem.id);
						window.location.href = "/pay/";
						// this.$store.dispatch("GET", {
						// 	url: "/api/zixun/?action=buyzx&tcid=" + checkitem.id
						// }).then(result => {
						// 	$alert({
						// 		msg: "购买成功！",
						// 		okcb: _ => {
						// 			window.history.back()
						// 		}
						// 	})
						// })
					}
				})

			},
		}
	}
</script>

<style scoped="scoped">
	.checkbox {
		float: left;
		width: 60px;
		text-align: center;
		padding-top: 4px;
	}

	.checkbox img {
		width: 30px;
	}

	.btnGoumai {
		position: fixed;
		bottom: 60px;
		width: 38%;
		height: 34px;
		line-height: 34px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		left: 52%;
	}

	.btnGoumai:active {
		background: #26A1FF;
	}

	.btnJifen {
		position: fixed;
		bottom: 60px;
		width: 38%;
		height: 34px;
		line-height: 34px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		left: 10%;
	}

	.btnJifen:active {
		background: #26A1FF;
	}


	.btnStarzx {
		position: fixed;
		bottom: 60px;
		width: 80%;
		height: 34px;
		line-height: 34px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		left: 10%;
	}

	.btnStarzx:active {
		background: #26A1FF;
	}
</style>
