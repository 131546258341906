<template>
	<div>
		<div v-for="zx in DataList">
			<div style="padding: 5%;font-size: 12px;">
				问题详情：
				<div style="padding-top: 10px; color: #000000;font-size: 14px;" v-html="zx.msgcontent">

				</div>
				<div style="text-align: right;">
					{{zx.cdate}}
				</div>
				<div style="padding-top: 20px;">
					<div class="imagebox" v-for="(img,idx) in zx.files" :style="{backgroundImage:'url('+img.src+')'}">
						<img :src="img.src" preview="detail" />
					</div>


				</div>
			</div>

			<div style="padding: 5%;font-size: 12px;" v-if="ReplyList.length>0">
				<div style="padding-bottom: 10px;">回复：</div>
				<div v-for="(rep,idx) in ReplyList" style="padding-top: 5px;">
					<div class="zjheader">
						<img :src="zjheader" />
					</div>
					<div class="zjword">
						<div v-for="(aws,i) in rep.replycontent" :class="[aws.type=='text'?'reptxt':'',aws.type=='image'?'repimage':'',aws.type=='voice'?'repvoice':'',]"
						 style="margin-bottom: 6px;;">
							<div v-if="aws.type=='text'" v-html="aws.value"></div>
							<div v-if="aws.type=='image'" style="display: inline-block; width: 100%; ">
								<div class="imagebox imagebox2" :style="{backgroundImage:'url('+aws.value+')'}">
									<img :src="aws.value" preview="detail" style="width: 100%; vertical-align: middle;" />
								</div>

							</div>
							<div v-if="aws.type=='voice'">
								<div class="voicebox" @click="playVoice">
									<div>{{aws.time/10}}S</div>
								</div>
								<div style="display: none;" class="voiceplayer">
									<audio :src="aws.value" controls="controls"></audio>
								</div>
							</div>
						</div>

						<div style="text-align: right; font-size: 12px; color: #888888; border-bottom: 1px solid #f6f6f6; padding:10px 0;">{{rep.cdate}}</div>

					</div>
				</div>
			</div>





		</div>
		<div style="height: 60px;"></div>
		<div class="btnreply" @click="showReplyPage">
			{{ReplyList.length>0?'再次回答':'立即回答'}}
		</div>
		<div class="mask" style="display: none; background: #f6f6f6;" id="replypage">
			<div style="height: 100%; overflow: auto;" id="replyinpage">
				<div style="padding: 30px;">
					<div class="replyitem" v-for="(c,idx) of ContentList">

						<img v-if="c.type=='image'" :src="c.value" style="width: 100%;" />
						<div style="padding: 10px; background: #FFFFFF;" v-if="c.type=='text'" v-html="c.value"></div>
						<audio v-if="c.type=='voice'" :src="c.value" controls="controls"></audio>

						<div class="remove" @click="removeImage(idx)"></div>
					</div>

					<div v-if="ContentList.length==0" style="text-align: center; padding: 50px;">
						请回复用户内容
						<div>{{log}}</div>
					</div>

				</div>

				<div class="btnreplyconfirm" v-if="ContentList.length>0" @click="submiteWrite">发表回复</div>

				<div style="height: 300px;"></div>



			</div>
			<div style="position: absolute; bottom: 50px; width: 100%;" v-if="inputtype=='keybord'">
				<div style="padding: 30px;">
					<textarea v-model="txt" style="width: 96%; padding: 2%; height: 80px; border-radius: 4px; border: 1px solid #CCCCCC; -webkit-appearance: none; "
					 placeholder="请输入回复内容"></textarea>
				</div>

			</div>

			<div style="position: absolute; bottom: 250px; width: 100%; " v-show="showVoice">
				<div style="width: 200px; margin: 0 auto; background: #FFFFFF; padding: 20px;border-radius: 4px;">
					<div style="width: 50%; margin: 0 auto;">
						<img src="https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578046145691893.gif" style="width: 100%;" />
					</div>
					<div style="text-align: center;">
						{{second/10}}秒
					</div>
				</div>

			</div>



			<div style="position: absolute; width: 100%; height: 40px; bottom: 0; left: 0; background: #FFFFFF; border-top: 1px solid #f6f6f6;">
				<div class="voice" v-if="inputtype=='voice'" @click="inputtype='keybord'"></div>
				<div class="keybord" v-if="inputtype=='keybord'" @click="inputtype='voice'"></div>
				<div class="addicon" @click="chooseFiles"></div>
				<div class="inputarea">
					<div class="btnvoice" v-if="inputtype=='voice'" @touchcancel="endVoice" @touchstart="startVoice" @touchend="endVoice">按住
						说话 (60S)</div>
					<div class="btninput" @click="addTxt" v-if="inputtype=='keybord'">点击 回复</div>
				</div>

			</div>
		</div>



		<div style="display: none;">
			<input type="file" id="imagefile" multiple="multiple" @change="uploadImage" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ImageList: [],
				msgcontent: "",
				taped: false,
				DataList: [],
				ReplyList: [],
				inputtype: "keybord",
				showVoice: false,
				ContentList: [],
				timer: 0,
				second: 0,
				txt: "",
				log: "",
				zjheader: "",
				replied: false

			}
		},
		mounted() {
			this.$store.dispatch("SHARE", {
				query: {
					shareurl: window.location.href.split('#')[0]
				},
				opt: {
					title: "过敏宝宝-专家咨询",
					desc: "欢迎咨询过敏宝宝，权威专家为您提供专业解答！",
					link: window.location.href.split("#")[0] + "#/zjlist",
					imgUrl: "https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1579008835861760.jpg",
					success: _ => {

					}
				}
			}).then(result => {

			})
			let t = setInterval(() => {
				if (window.wx) {
					clearInterval(t);
					wx.onVoiceRecordEnd({
						// 录音时间超过一分钟没有停止的时候会执行 complete 回调
						complete: (res) => {
							alert("超过60秒：" + res.localId)
							var localId = res.localId;
							this.uploadVoice(localId, voice => {
								if (voice.data.url) {
									this.ContentList.push({
										type: "voice",
										value: voice.data.url,
										time:600
									});
									$("#replyinpage").scrollTop(99999)
								}

							})
						}
					})
				}
			}, 500)
			this.getList();

		},
		methods: {
			startVoice() {
				this.showVoice = true;
				wx.startRecord();
				this.timer = setInterval(() => {
					this.second += 1;
				}, 100)
			},
			endVoice() {
				this.showVoice = false;

				clearInterval(this.timer);
				if (this.second > 10) {
					let lasttime = this.second;
					wx.stopRecord({
						success: (res) => {
							this.log = res.localId
							var localId = res.localId;
							this.uploadVoice(localId, voice => {
								this.log = JSON.stringify(voice)
								this.ContentList.push({
									type: "voice",
									value: voice.data.url,
									time:lasttime
								});
								$("#replyinpage").scrollTop(99999)
								
							})
						}
					});



				} else {
					$Toast("时间太短，请重试");
					wx.stopRecord({
						success: (res) => {

						}
					});
					//this.second = 0;
				}
				
				this.second = 0;
				
			},
			uploadVoice(localId, success) {
				$("#loading").show()
				wx.uploadVoice({
					localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
					isShowProgressTips: 0, // 默认为1，显示进度提示
					success: (res2) => {
						var serverId = res2.serverId; // 返回音频的服务器端ID
						this.$store.dispatch("POST", {
							url: "/api/zixun/?action=uploadvoice",
							query: {
								serverid: serverId
							}
						}).then(result => {
							if (success) {
								success(result)
							}
							$("#loading").hide()
						})
					}
				});



			},
			addTxt() {
				if (this.txt) {
					this.ContentList.push({
						type: "text",
						value: this.txt.replace(/\n/g, "<br />")
					})

					$("#replyinpage").scrollTop(99999)
					this.txt = "";
				}
			},


			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zxinfo&id=" + this.$route.params.id
				}).then(result => {
					for (let item of result.data.zx) {
						item.msgcontent = item.msgcontent.replace(/\\n/g, "<br />");
						item.files = JSON.parse(item.files.replace(/\\"/g, "\""));
						item.cdate = this.getCdate(item.cdate)
						this.zjheader = item.zj_headimg
						this.replied = item.replied == 1 ? true : false
					}
					if (result.data.reply) {
						for (let item of result.data.reply) {

							item.replycontent = JSON.parse(decodeURIComponent(item.replycontent).replace(/\\"/g, "\""));
							item.cdate = this.getCdate(item.cdate)
						}
					}
					this.DataList = result.data.zx;
					this.ReplyList = result.data.reply;
					$("#loading").hide()
					setTimeout(() => {
						this.$previewRefresh()
					}, 1000)
				})
			},
			
			playVoice(e){
				let player = $(e.target).parent().parent().find(".voiceplayer").find("audio")[0];
				console.log(player.paused)
				if(!player.paused){
					player.pause()
					player.currentTime =0
				}else{
					player.play()
				}
				//.play()
			},

			chooseFiles() {
				document.getElementById("imagefile").click()
			},
			uploadImage() {
				let files = document.getElementById("imagefile").files;

				let upload = (idx) => {
					if (idx < files.length && this.ImageList.length < 9) {
						this.uploadOss({
							file: files[idx],
							onSuccess: res => {

								this.ContentList.push({
									type: "image",
									value: res.src
								});
								$("#replyinpage").scrollTop(99999)

								upload(idx + 1)
							}
						})
					} else {


						$("#loading").hide()
					}
				}
				$("#loading").show();
				$Toast("上传中，请稍等...")
				upload(0);


			},
			getCdate(e) {
				let d = new Date(e);
				let y = d.getFullYear();
				let m = d.getMonth() + 1;
				let day = d.getDate();
				let hour = d.getHours()
				let min = d.getMinutes()
				if (m < 10) m = "0" + m
				if (day < 10) day = "0" + day
				if (hour < 10) hour = "0" + hour
				if (min < 10) min = "0" + min

				return y + "-" + m + "-" + day + " " + hour + ":" + min
			},
			removeImage(idx) {
				this.ContentList.splice(idx, 1)
			},
			showReplyPage() {
				$("#replypage").animate({
					translateX: "100%"
				}, 30, "linear", function() {
					$(this).show().animate({
						translateX: "0%"
					}, 300, "ease-out", function() {

					})
				})
			},
			hideReplyPage() {

				$("#replypage").animate({
					translateX: "100%"
				}, 300, "ease-in", function() {
					$("#replypage").hide()
				})

			},
			submiteWrite() {

				if (this.taped) {
					return
				} else {
					setTimeout(() => {
						this.taped = false
					}, 2000)
				}
				$confirm({
					msg: "确认提交?",
					okcb: _ => {
						this.taped = true;
						$("#loading").show();
						this.$store.dispatch("POST", {
							url: "/api/zixun/?action=zxreply",
							query: {
								zx_id: this.$route.params.id,
								msgcontent: encodeURIComponent(JSON.stringify(this.ContentList))
							}
						}).then(result => {
							let data = result.data


							if (data.code == 200 && data.msg == "ok") {
								setTimeout(() => {
									$alert({
										msg: "提交成功，感谢您的回答！",
										okcb: () => {
											window.history.back()
										}
									})
								}, 300)

							} else {
								setTimeout(() => {
									$alert({
										msg: data.msg
									})
								}, 300)

							}

							$("#loading").hide()
						})
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.imagebox {
		width: 29vw;
		height: 29vw;
		margin-right: 1.5vw;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 1.5vw;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border: 1px solid #f6f6f6;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}

	.imagebox:nth-child(3n) {
		margin-right: 0;
	}

	.imagebox img {
		opacity: 0;
	}

	.imagebox2 {
		width: 100%;
		height: 23vw;

	}

	.voice {
		width: 40px;
		height: 40px;
		float: left;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311383266.png);
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.keybord {
		width: 40px;
		height: 40px;
		float: left;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311267128.png);
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.addicon {
		width: 40px;
		height: 40px;
		float: right;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311350187.png);
		background-size: 60%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.inputarea {
		margin-left: 40px;
		margin-right: 40px;
		height: 100%;
		-webkit-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		touch-callout: none;


	}

	.inputarea div {
		height: 30px;
		text-align: center;
		line-height: 30px;
		border: 1px solid #f6f6f6;
		transform: translateY(4px);
		border-radius: 4px;
	}

	.inputarea div:active {
		background-color: #f6f6f6;
	}

	.btnreply {
		position: fixed;
		width: 100%;
		height: 40px;
		bottom: 0;
		left: 0;
		background: #007AFF;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		border-top: 1px solid #f6f6f6;
	}

	.replyitem {
		padding-bottom: 20px;
		position: relative;
		word-break: break-all;
		color: #000000;
	}

	.remove {
		position: absolute;
		width: 20px;
		height: 20px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586239857155945387.png);
		background-size: cover;
		right: -22px;
		top: 0px;
	}

	.btnreplyconfirm {
		width: 80%;
		margin: 0 auto;
		height: 40px;
		border-radius: 4px;
		line-height: 40px;
		background: #007AFF;
		color: #FFFFFF;
		text-align: center;
	}

	.zjheader {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		float: left;
	}

	.zjheader img {
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}

	.zjword {
		margin-left: 50px;
		min-height: 45px;
		padding-top: 2px;
		color: #000000;
	}

	.repimage {
		width: 30%;
		display: inline-block;
		margin-right: 3%;
		margin-bottom: 6px;
	}

	.voicebox {
		width: 50%;
		height: 20px;
		padding: 4px;
		background-color: #0000FF;
		border-radius: 14px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/157975006840994.png);
		background-size: 20px 20px;
		background-position:4px 4px;
		background-repeat: no-repeat;
		min-width: 50px;
	}
	.voicebox div{
		margin-left: 25px;
		line-height: 20px;
		color: #FFFFFF;
	}
	.voicebox:active{ background-color:#007AFF ;}
</style>
