<template>
	<div>
		<div v-for="(item,idx) in DataList" @click="ziXun(item)">
			<div class="dataitem">
				<div class="zjhead">
					<img :src="item.headimgurl" />
				</div>
				<div class="zjinfo" @click="goInfo(item)">
					<div class="zjname">
						{{item.nickname}}
						<div style="float: right;">
							<span v-if="item.replied==1" style="color: #0C85E1;">已回答</span><span style="color: #FE0346;" v-else>未回复</span>
						</div>
					</div>
					<div class="zj_zhicheng">
						{{item.msgcontent}}
					</div>
					
					<div style="text-align: right; padding: 5px 15px; font-size: 12px;">{{item.cdate}}</div>
					
				</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: "",
				DataList: []

			}
		},

		mounted() {

			this.getList();
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjzxlist"
				}).then(result => {

					this.DataList = result.data.rows;
					$("#loading").hide()
				})
			},
			
			goInfo(item) {
				this.$router.push("/zxreply/" + item.id)
			}
		}
	}
</script>

<style scoped="scoped">
	.dataitem {
		padding: 15px 15px 0 15px;
		position: relative;
		border-bottom: 1px solid #f6f6f6;
	}

	.zjhead {
		width: 50px;
		height: 50px;
		float: left;
	}

	.zjhead img {
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}

	.zjinfo {
		margin-left: 60px;
		min-height: 60px;
	}

	.zjname {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		color: #000;
		font-weight: bold;
	}

	.zj_zhicheng {
		color: #474747;
		font-size: 13px;
		padding-top: 6px;
		max-height: 55px;
		overflow: hidden;
		position: relative;
		line-height: 18px;
	}

	.zj_desc {
		padding-top: 6px;
		font-size: 12px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;



	}

	.btnzj {
		width: 60px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #0C85E1;
		color: #FFFFFF;
		position: absolute;
		right: 10px;
		top: 38px;
		border-radius: 4px;
		font-size: 12px;
	}

	.searchbar {
		position: fixed;
		height: 50px;
		top: 0;
		width: 100%;
		z-index: 1;
		background: rgba(255, 255, 255, 0.8);
	}

	.searcbox {
		width: 70%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		padding-left: 30px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586240157273748023.png);
		background-size: auto 80%;
		background-repeat: no-repeat;
		background-position: left center;
	}

	.searchbtn {
		height: 30px;
		width: 50px;
		float: right;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 4px;
	}
</style>
