<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
  /*导入全局的样式文件*/
  @import "./common/css/main.css";
</style>
