import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuephotopreview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  fullscreenEl: false
};
Vue.use(vuephotopreview)

import Video from 'video.js'
import 'video.js/dist/video-js.css'


Vue.config.productionTip = false
Vue.prototype.$video = Video

//字符串encode
Vue.filter('encode', function(value) {
  if (!value) { return ''}
  value = decodeURIComponent(value)
  return value;
});
//金额保留2位小数点
Vue.filter('twobit', function(value) {
  if (!value) { return ''}
  value =parseFloat(value).toFixed(2);
  return value;
});



Vue.prototype.uploadOss = (fileObj) => {
  var myDate = new Date();
  var ossPath = 'gmbaby/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
  // 获取文件后缀
  var pathArr = fileObj.file.name.split('.')
  //  随机生成文件名称
  var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
  var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
  // 要提交的key
  var fileKey = ossPath + "/" + fileName;
  let formData = {
    name: fileObj.file.name,
    key: fileKey,
    policy: 'eyJleHBpcmF0aW9uIjoiMjA1MC0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
    OSSAccessKeyId: 'LTAIXRT0vIvxgjxk',
    signature: '1B6Njnb5MJxacYITBMEjv/eXubE=',
    success_action_status: "200",

  };
  var host = 'https://gmbaby.oss-cn-shanghai.aliyuncs.com';
  var outurl = host
  var form = new FormData();
  // 其他参数
  for (let k in formData) {
    form.append(k, formData[k]);
  }
  form.append("file", fileObj.file);
  // XMLHttpRequest 对象
  var xhr = new XMLHttpRequest();
  xhr.open("post", host, true);
  xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
  xhr.onload = (res) => {
    if (fileObj.onSuccess) {


      fileName = fileName.toLocaleLowerCase();
      //计算图片高度
      if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 || fileName
        .indexOf(
          ".gif") > 0 || fileName.indexOf(".bmp") > 0) {

        var reader = new FileReader();
        reader.readAsDataURL(fileObj.file);
        reader.onload = function(theFile) {
          var image = new Image();
          image.src = theFile.target.result;
          image.onload = function() {
            fileObj.onSuccess({
              src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this.height,
              fileName: fileObj.file.name
            })

          };
        };

      } else {
        fileObj.onSuccess({
          src: outurl + "/" + fileKey,
          fileName: fileObj.file.name
        })
      }

    }
  };
  xhr.onerror = res => {
    if (fileObj.onError) {
      fileObj.onError(res)
    }

  }
  xhr.send(form);

}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
