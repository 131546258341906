<template>
	<div>
		<div style="padding: 5%;font-size: 12px;">
			请输入您的问题详情：
			<div style="padding-top: 10px;">
				<textarea class="qstinput" v-model="msgcontent" placeholder="请输入内容"></textarea>
			</div>
			<div style="padding-top: 10px;">
				<div class="imagebox" v-for="(img,idx) in ImageList" :style="{backgroundImage:'url('+img.src+')'}">
					<div class="remove" @click="removeImage(idx)"></div>
				</div>

				<div v-if="ImageList.length<9" class="imagebox" @click="chooseFiles" style="background-image: url(../../../../static/images/add.png); background-size: 50%;">

				</div>
			</div>
		</div>
		<div style="display: none;">
			<input type="file" id="imagefile" multiple="multiple" @change="uploadImage" />
		</div>

		<div class="btnStarzx" @click="submiteWrite">提交咨询</div>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				ImageList: [],
				msgcontent: "",
				taped: false

			}
		},
		created() {


		},
		mounted() {

		},
		methods: {
			chooseFiles() {
				document.getElementById("imagefile").click()
			},
			uploadImage() {
				let files = document.getElementById("imagefile").files;

				let upload = (idx) => {
					if (idx < files.length && this.ImageList.length < 9) {
						this.uploadOss({
							file: files[idx],
							onSuccess: res => {
								this.ImageList.push(res);
								console.log(idx + 1)
								upload(idx + 1)
							}
						})
					} else {

						
						$("#loading").hide()
					}
				}
				$("#loading").show();
				$Toast("上传中，请稍等...")
				upload(0);


			},
			removeImage(idx) {
				this.ImageList.splice(idx, 1)
			},
			submiteWrite() {
				if(!this.msgcontent){
					return
				}
				if(this.taped){
					return
				}else{
					setTimeout(()=>{
						this.taped = false
					},2000)
				}
				$confirm({
					msg: "确认提交?",
					okcb: _ => {
						this.taped = true;
						$("#loading").show();
						this.$store.dispatch("POST", {
							url: "/api/zixun/?action=zxsave",
							query: {
								zj_id:this.$route.params.id,
								msgcontent: this.msgcontent,
								files: JSON.stringify(this.ImageList)
							}
						}).then(result => {
							let data = result.data
							
							
							if (data.code == 200) {
								setTimeout(()=>{
									$alert({
										msg: "提交成功，请耐心等待专家回复！",
										okcb: () => {
											window.history.back()
										}
									})
								},300)
								
							} else {
								setTimeout(()=>{
									$alert({
										msg: data.msg
									})
								},300)
								
							}
							
							$("#loading").hide()
						})
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.qstinput {
		width: 96%;

		padding: 2%;
		font-size: 14px;
		margin: 0;
		border: 1px solid #f6f6f6;
		background: rgba(255, 255, 255, 0.7);
		height: 120px;
	}

	.imagebox {
		width: 29vw;
		height: 29vw;
		margin-right: 1.5vw;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 1.5vw;
		background-position: center;
		background-size: 100%;
		background-repeat: no-repeat;
		border: 1px solid #f6f6f6;
		box-sizing: border-box;
		position: relative;
	}

	.imagebox:nth-child(3n) {
		margin-right: 0;
	}

	.remove {
		position: absolute;
		width: 20px;
		height: 20px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586239857155945387.png);
		background-size: cover;
		right: -4px;
		top: -4px;
	}


	.btnStarzx {
		position: fixed;
		bottom: 60px;
		width: 80%;
		height: 34px;
		line-height: 34px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		left: 10%;
	}

	.btnStarzx:active {
		background: #26A1FF;
	}
</style>
