<template>
	<div class="indexContent">
		<v-header></v-header>
		<div class="scrollList">
			<div class="swiper-container gallery-top swiper-container-horizontal">
				<div class="swiper-wrapper">
					<div v-for="value in lbt" class="swiper-slide swiper-slide-next" style="width: 100%; height: 100%; margin-right: 10px;"
					 v-bind:style="{backgroundImage: 'url(' + value.image + ')'}"></div>
				</div>
			</div>
			<div class="swiper-pagination" style="width: 93%;text-align: right;margin-right: 15px;bottom: 20px;position: relative;"></div>
		</div>
		<div class="proType">
			<div class="proTypeLeft" @click="toCatalogList(1)">
				<div style="color: #5c91e2;font-size: 18px;text-align: center;margin-top: 28px;">肠胃调理</div>
				<div class="flexcenter" style="margin-top: 15px;">
					<img src="../../public/static/images/cw.png" height="70%" width="70%" />
				</div>
			</div>
			<div class="proTypeRight">
				<div style="height: 68px;background: #FFFFFF;border-radius: 10px;display: flex;padding-left: 27px;padding-right: 20px;">
					<div style="flex: 1;" @click="toCatalogList(2)">
						<div style="font-size: 15px;color: #5d91e2;margin-top: 17px;">婴儿皮肤管理</div>
						<div style="font-size: 12px;color: #5d91e2;margin-top: 3px;">(嘟卡系列)</div>
					</div>
					<div class="flexcenter" style="width: 46px;">
						<img src="../../public/static/images/yf.png" width="100%" />
					</div>
				</div>
				<div style="display: flex;margin-top: 5px;">
					<div style="flex: 1;margin-right: 10px; height: 93px; background: #FFFFFF;border-radius: 10px;" @click="toCatalogList(3)">
						<div style="font-size: 12px;color: #5d91e2;margin-top: 16px;text-align: center;">特殊配方</div>
						<div class="flexcenter" style="margin-top: 5px;">
							<img src="../../public/static/images/np.png" height="60%" width="60%" />
						</div>
					</div>
					<div style="flex: 1;margin-right: 10px;height: 93px; background: #FFFFFF;border-radius: 10px;" @click="toCatalogList(4)">
						<div style="font-size: 12px;color: #5d91e2;margin-top: 16px;text-align: center;">基础护理</div>
						<div class="flexcenter" style="margin-top: 5px;">
							<img src="../../public/static/images/nf.png" height="60%" width="60%" />
						</div>
					</div>
					<div style="flex: 1;height: 93px; background: #FFFFFF;border-radius: 10px;" @click="toCatalogList(5)">
						<div style="font-size: 12px;color: #5d91e2;margin-top: 16px;text-align: center;">生物抗菌</div>
						<div class="flexcenter" style="margin-top: 10px;">
							<img src="../../public/static/images/nz.png" height="50%" width="50%" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="proList">
			<div class="hotTitle">
				<div class="hots flexleft"><span></span>热销产品</div>
				<div class="more" @click="getproList">更多热销产品></div>
			</div>
			<div class="hotList">
				<div class="hotlist_left" v-for="hot in hotList" @click="toProDetail(hot.id)">
					<div class="hotimg">
						<img v-bind:src="hot.headimg" />
					</div>
					<div class="proname">{{hot.title}}</div>

					<div style="position: absolute; top: 0px; left: 0px;">
						<svg class="icon" style="width: 40px; height: 40px;vertical-align: middle;fill: #007AFF;overflow: hidden;"
						 viewBox="0 0 1028 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8234">
							<path d="M527.961631 0L0 527.961631v496.038369L1024 0z" fill="#007AFF" p-id="8235"></path>
							<path d="M260.998972 676.70298l-156.108256-156.108256 31.572456-31.572456 61.390888 61.390887L259.595752 488.671463l-61.390887-61.390887 31.572456-31.572457 156.108256 156.108256-31.572456 31.572457-68.406989-68.406989-61.741692 61.741692 68.406989 68.406989-31.572457 31.572456zM335.018842 448.32888c-15.786228-15.786228-27.01199-31.572456-32.975677-47.358685-4.560466-11.576567-6.665296-23.50394-7.016101-35.782117-0.350805-12.628983 1.754025-23.854745 5.963686-34.378897 5.612881-13.681398 14.733813-27.01199 27.362796-39.991778 23.153135-23.153135 48.4111-34.378897 76.475505-33.677287 28.064406 0.350805 54.725591 13.330593 80.334361 38.939363 25.257965 25.257965 38.237753 51.91915 38.588558 79.632751 0.70161 28.064406-10.524152 53.322371-33.677287 76.475506-23.153135 23.153135-48.761905 34.729702-76.475505 34.028091-27.362795-0.350805-54.023981-12.979788-78.580336-37.886947z m31.221651-33.326482c17.540254 17.540254 35.080507 27.01199 52.620761 28.064406 17.189448 1.052415 32.274066-4.911271 44.903049-17.540254 12.628983-12.628983 18.241864-27.362795 17.189448-44.552244-1.052415-17.189448-10.524152-34.729702-28.766015-52.971565-17.891059-17.891059-35.080507-27.362795-51.919151-28.415211-16.487838-1.052415-31.572456 4.911271-44.552244 17.891059-12.979788 12.979788-18.943474 28.064406-17.891058 44.903049 1.052415 17.189448 10.524152 34.378897 28.41521 52.62076zM623.38061 314.321343l-129.797876-129.797876-46.306269 46.306269-26.310381-26.31038 124.184995-124.184995 26.31038 26.31038-46.306269 46.30627 129.797876 129.797876-31.572456 31.572456z"
							 fill="#FFFFFF" p-id="8236"></path>
						</svg>
					</div>
				</div>
				<div style="height: 100px; width: 100%; float: left;"></div>
			</div>

		</div>
	</div>
</template>

<script>
	import vHeader from '../components/Header.vue'
	import Swiper from '../../public/static/swiper.min.js'
	let galleryTop
	let galleryThumbs
	export default {
		data() {
			return {
				lbt: [],
				hotList: [], //热销列表
			}
		},
		created() {

			this.GetLunboList(); //轮播图
			this.GetHotList(); //热销
		},
		mounted() {

		},
		methods: {
			lunbo() {
				let that = this;
				galleryTop = new Swiper('.gallery-top', {
					// 如果需要分页器
					pagination: '.swiper-pagination',
					autoplay: 3000,
					speed: 300,
					spaceBetween: 10,
					grabCursor: true,
					initialSlide: 1,
					autoplayDisableOnInteraction: false,
					onClick: function(swiper) {
						for (var i = 0; i < that.lbt.length; i++) {
							if (i == swiper.activeIndex) {
								if (that.lbt[i].outhref != "" && that.lbt[i].outhref.indexOf("http") >= 0) {
									window.location.href = that.lbt[i].outhref
								} else {
									console.log("没有外链")
								}
							}
						}

					}
				})
				galleryThumbs = new Swiper('.swiper-pagination', {
					spaceBetween: 10,
					autoplay: 4000,
					initialSlide: 1,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true,
					autoplayDisableOnInteraction: false,
					grabCursor: true
				})
				galleryTop.params.control = galleryThumbs
				galleryThumbs.params.control = galleryTop
			},
			//查看更多
			getproList() {
				this.$router.push('/prolist');
			},
			//查看商品详情
			toProDetail(id) {
				this.$router.push({
					path: '/prodetail/' + id

				});
			},
			toCatalogList(id) {
				this.$router.push({
					path: '/cataloglist/' + id

				});
			},
			//轮播图
			GetLunboList() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=carousel"
				}).then(result => {
					self.lbt = result.data.data.rows.rows;
					this.$nextTick(() => {
						this.lunbo();
					})

				})
			},
			//热销商品
			GetHotList() { //获取热销商品
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=hot"
				}).then(result => {
					$("#loading").hide()
					this.hotList = result.data.data.rows;
				})
			}

		},
		components: {
			vHeader
		}
	}
</script>

<style scoped="scoped">
	@import url("../common/css/swiper.min.css");

	.gallery-top {
		height: 50vw;
		width: 100%;
	}

	.gallery-thumbs {
		height: 20%;
		box-sizing: border-box;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0.3;
		cursor: pointer;
	}

	.gallery-thumbs .swiper-slide {
		width: 30%;
		opacity: 0.3;
	}

	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 100%;
		border: 1px solid #656565;
		margin-left: 10px;
	}

	.swiper-slide {
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
	}

	.header {
		width: 100%;
		height: 54px;
	}

	.scrollList {
		width: 100%;
		height: 50vw;
	}

	.proType {
		width: 100%;
		height: 47.5vw;
		display: flex;
		margin-top: 10px;
	}

	.proTypeLeft {
		margin-left: 10px;
		width: 33%;
		height: 166px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.proTypeRight {
		flex: 1;
		margin-left: 10px;
		margin-right: 10px;
	}

	.proList {
		height: 500px;
		/*border: 1px solid red;*/
		margin-bottom: 60px;
	}

	.hotTitle {
		height: 36px;
		line-height: 36px;
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
	}

	.hots {
		flex: 1;
		font-size: 16px;
		color: #5586d2;
	}

	.hots span {
		background: #5586d2;
		width: 6px;
		height: 26px;
		display: inline-block;
		margin-right: 5px;
	}

	.more {
		flex: 1;
		text-align: right;
		font-size: 12px;
		color: #a3a3a3;
	}

	.hotList {
		margin-top: 10px;
		padding-left: 2.6%;
	}

	.hotlist_left {
		float: left;
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
		position: relative;
	}

	.hotimg {
		text-align: center;
		padding-top: 20px;
	}

	.hotimg img {
		width: 40vw;
		height: 40vw;
	}

	.hotlist_middle {
		float: left;
		width: 2.5%;
	}

	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		padding: 0px 5%;
		height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 90%;
		line-height: 20px;
	}

	.clear {
		clear: both;
	}
</style>
