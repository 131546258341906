<template>
	<div>
		<div v-for="zj in DataInfo" style="padding: 15px;">
			<div class="zjhead">
				<img :src="zj.zj_headimg" />
			</div>
			<div class="zjinfo">
				<div class="zjname">
					{{zj.zj_name}} {{zj.zj_zhicheng}}
				</div>
				<div class="zj_zhicheng">
					{{zj.zj_hospital}}
				</div>
				<div class="zj_desc">
					{{zj.zj_desc}}
				</div>
				<div class="zj_zhicheng" style="text-align: right;font-size: 12px;">
					已服务用户：{{zj.zj_vt_count+zj.zj_real_count}}人
				</div>
			</div>
			<div style="color: #474747; padding-top: 20px;">
				<div v-html="zj.zj_content"></div>
			</div>
		</div>
		<div style="height: 100px;"></div>
		<div class="btnStarzx" v-if="chance>-1" @click="goWrite">立即咨询 <span style="font-size: 11px;">(剩余{{chance}}次)</span></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataInfo: [],
				chance:-1
			}
		},
		mounted() {
			this.getList();
			this.getMyZixun();
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjone&id=" + this.$route.params.id
				}).then(res => {
					for (let item of res.data.rows) {
						item.zj_vt_count = parseInt(item.zj_vt_count)
						item.zj_real_count = parseInt(item.zj_real_count)
						item.zj_content = decodeURIComponent(item.zj_content)
						item.zj_desc = decodeURIComponent(item.zj_desc)
					}
					this.DataInfo = res.data.rows
					$("#loading").hide()
				})
			},
			getMyZixun(){
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=myzx"
				}).then(res => {
					this.chance = res.data.num
				})
			},
			goWrite(){
				if(this.chance>0){
					this.$router.push("/zxwrite/"+this.$route.params.id)
					
				}else{
					
					this.$router.push("/zxbuy")
				}
				
				
				
				
			}
		}
	}
</script>

<style scoped="scoped">
	.zjhead {
		float: left;
		width: 120px;
		height: 120px;
	}

	.zjhead img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.zjinfo {
		margin-left: 130px;
		min-height: 120px;
	}

	.zjname {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		color: #000;
		font-weight: bold;
	}

	.zj_zhicheng {
		color: #474747;
		font-size: 13px;
		padding-top: 6px;
	}

	.zj_desc {
		padding-top: 6px;
		font-size: 12px;




	}

	.btnStarzx {
		position: fixed;
		bottom: 60px;
		width: 80%;
		height: 34px;
		line-height: 34px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		left: 10%;
	}
	.btnStarzx:active{background:#26A1FF;}
</style>
