<template>
	<div class="myinvite">
		<div class="title">共邀请{{DataList.length}}位好友，消费总额￥{{Totalmoney}}</div>
		<div class="inviteList" v-for="item of DataList" @click="ShowFriend(item)">
			<div class="img flexcenter">
				<img :src="item.headimgurl" height="60" width="60" />
			</div>
			<div class="info">
				<div class="name">{{item.nickname}}</div>
				<div class="counts">
					<div class="count_left">日期：{{item.cdate}}</div>

				</div>
				<div >
					<div class="count_left">消费总额<span style="color: #007AFF;">￥{{item.tmoney==''?'0':parseFloat(item.tmoney).toFixed(2)}}</span>，邀请好友<span style="color: #007AFF;">{{item.invitecount}}</span>人</div>

				</div>
			</div>
		</div>

		<div style="height: 80px; text-align: center; line-height: 80px;" v-show="DataList.length==0">
			还没有邀请好友
		</div>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				Totalmoney:0
			}
		},
		mounted() {
			this.GetMy()
		},
		methods: {
			GetMy() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=invite"
				}).then(result => {
					this.DataList = result.data.data.invent.rows
					let tmoney=0;
					for(let item of this.DataList){
						if(item.tmoney==''){
							item.tmoney=0;
						}
						if(item.invitecount==''){
							item.invitecount=0;
						}
						tmoney+=parseFloat(item.tmoney)
						
					}
					this.Totalmoney=tmoney.toFixed(2)
				})
			},
			ShowFriend(item){
				this.$router.push("/friendinfo/"+item.id)
			}

		}
	}
</script>

<style scoped="scoped">
	.myinvite {
		height: 100%;
		width: 100%;
	}
	
	.title {
		min-height: 42px;
		line-height: 42px;
		background: #94bdf9;
		font-size: 18px;
		color: #FFFFFF;
		padding-left: 20px;
	}
	
	.inviteList {
		background: #f6fafe;
		min-height: 96px;
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		margin-top: 15px;
	}
	
	.img {
		width: 62px;
	}
	
	.img img {
		border: 1px solid #FFFFFF;
		border-radius: 50%;
	}
	
	.info {
		flex: 1;
		margin-left: 20px;
	}
	
	.name {
		font-size: 17px;
		color: #474747;
		margin-top: 15px;
	}
	
	.counts {
		display: flex;
		margin-top: 7px;
		font-size: 12px;
	}
	
	.count_left {
		font-size: 12px;
		color: #a4a4a4;
		flex: 1;
	}
	
	.count_right {
		font-size: 14px;
		color: #979899;
	}
	
	.count_right span {
		color: #d92b2b;
	}
</style>