<template>
	<div class="prolist">
		<div class="lists">
			<div class="hotlist_left" v-for="hot in productlist" @click="toProDetail(hot.id)">
				<div class="hotimg">
					<img v-bind:src="hot.headimg" />
				</div>
				<div class="proname">{{hot.title}}</div>
			</div>
			
			<div style="height: 100px; text-align: center; float: left; width: 100%; line-height: 30px;">
				没有更多商品了
			</div>
		</div>

	</div>
</template>

<script>
	import vHeader from '../components/Header.vue'
	export default {
		data() {
			return {

				productlist: []

			}
		},
		created() {
			let id = this.$route.params.id;
			
			if(id == 1) {
				document.title = "肠胃调理";
			} else if(id == 2) {
				document.title = "婴儿皮肤管理";
			} else if(id == 3) {
				document.title = "特殊配方";
			} else if(id == 4) {
				document.title = "基础护理";
			} else if(id == 5) {
				document.title = "生物抗菌";
			}
			this.GetHotList();
		},
		methods: {
			toProDetail(id) {
				this.$router.push("/prodetail/" + id)
			},

			GetHotList() { //获取热销商品
				console.log(123)
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=catalog&id=" + this.$route.params.id
				}).then(result => {
					this.productlist = result.data.data.rows;
				})
			}

		}
	}
</script>

<style scoped="scoped">
	ul {
		display: flex;
		height: 36px;
		line-height: 36px;
		background: #f6fafe;
	}
	
	li {
		flex: 1;
		text-align: center;
		font-size: 18px;
		color: #7b7b7b;
		list-style: none;
	}
	
	.active {
		color: #5c91e2;
		border-bottom: 2px solid #26a1ff;
	}
	
	.prolist {
		height: 100%;
		width: 100%;
		margin-bottom: 50px;
	}
	
	.lists {
		margin-top: 10px;
		padding-left: 2.6%;
	}
	
	.hotlist_left {
		float: left;
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
	}
	
	.hotimg {
		text-align: center;
		padding-top: 20px;
	}
	
	.hotimg img {
		width: 40vw;
		height: 40vw;
	}
	
	.hotlist_middle {
		float: left;
		width: 2.5%;
	}
	
	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		padding: 0px 5%;
		height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 90%;
		line-height: 20px;
	}
	
	.clear {
		clear: both;
	}
</style>