<template>
	<div class="orderList">
		<div class="title">
			<div :class="['item',showType==1?'active':'']" @click="ShowType(1)">未完成</div>
			<div :class="['item',showType==2?'active':'']" @click="ShowType(2)">已完成</div>
		</div>
		<div v-show="ShowList.length==0" style="line-height: 100px; text-align: center;">
			还没有订单
		</div>
		<div v-for="item of ShowList" class="oneorder">
			<div class="ordernum">
				{{item.orderid}}
			</div>
			<div class="orders" v-for="p of item.children" @click="ShowInfo(item)">
				<div class="proimg flexcenter">
					<img :src="p.headimg">
				</div>
				<div class="proInfo">
					<div class="pros">
						<div class="proname">
							{{p.title}}
						</div>
						<div class="counts">
							<div class="price">{{ p.price|filtermoney}}<span> &times; {{p.number}}</span></div>
						</div>
					</div>
				</div>
			</div>
			<div style="padding: 0 20px; background: #f6fafe;">
				<div class="frees">合计：{{item.totalmoney|filtermoney}}
					<span v-show="item.expressprice!=''&&item.expressprice>0">（含运费￥{{parseFloat(item.expressprice).toFixed(2)}}）</span>
					<span v-show="item.expressprice==''||item.expressprice==0">（包邮）</span>
					<span v-show="item.activity=='twobuy'">（买一送一）</span> 积分:{{item.usescore}}
				</div>
				<div class="buttons">
					<div style="flex: 1;"></div>
					
					<div class="shh" v-show="item.paystatus==2&&item.expressnum!=''" @click="ShowExpress(item)">查看物流</div>
					<div class="shh" v-show="item.paystatus==2" @click="Confirm(item)">确认收货</div>
					<div class="wuli" v-show="item.paystatus==1">已付款</div>
					<div class="shh" v-show="item.paystatus==0" @click="Pay(item)">支付</div>
					<div class="shh" v-show="item.paystatus==3&&item.comment==0" @click="GoComment(item)">去评价</div>
					<div class="shh" v-show="item.paystatus==3" @click="ReBuy(item)">再次购买</div>
					<div class="shh" v-show="item.paystatus!=1&&item.paystatus!=2" @click="Delete(item)">删除</div>
				</div>
			</div>
		</div>
		<!--https://m.kuaidi100.com/result.jsp?nu=779007205189-->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				showType: 1,
				ShowList: [],

			}
		},
		beforeMount() {
			this.GetList();
		},
		filters: {
			filtermoney: function(value) {
				if(!isNaN(value)) {
					value = parseFloat(value)
					return '￥' + value.toFixed(2);
				}

			}
		},
		methods: {
			ShowType(type) {
				if(this.showType == type) {

					return;
				} else {
					this.showType = type
				}
				if(type == 1) {
					this.ShowList = []
					for(let item of this.DataList) {
						if(item.paystatus != 3) {
							this.ShowList.push(item)
						}
					}
				} else {
					this.ShowList = []
					for(let item of this.DataList) {
						if(item.paystatus == 3) {
							this.ShowList.push(item)
						}
					}

				}
			},
			GetList() {
				this.$store.dispatch("GET", {
					url: "api/wx/?action=orderlist"
				}).then(result => {
					let orderid = "";
					let data = [];
					this.ShowList = [];
					for(let item of result.data.data.rows) {
						let ext = false
						let exto = null;
						for(let o of data) {
							if(item.orderid == o.orderid) {
								ext = true;
								exto = o;

								break;
							}

						}
						if(!ext) {
							data.push({
								id:item.order_id,
								orderid: item.orderid,
								paystatus: item.paystatus,
								totalmoney: item.totalmoney,
								expressprice: item.expressprice,
								usescore: item.usescore,
								gooddesc: item.gooddesc,
								comment: item.comment,
								activity: item.activity,
								children: [item]
							})
						} else {
							exto.children.push(item)
						}
					}
					console.log(data)
					this.DataList = data;
					for(let item of this.DataList) {
						if(item.paystatus != -1) {
							this.ShowList.push(item)
						}
					}

				})
			},

			//跳转到订单详情
			toorderDetail(id) {
				this.$router.push({
					path: '/orderdetail',
					query: {
						id: id
					}
				})
			},
			ShowExpress(item) {

				window.location.href = "https://m.kuaidi100.com/result.jsp?nu=" + item.children[0].expressnum
			},
			Confirm(item) {
				this.$store.dispatch("POST", {
					url: "api/wx/?action=paystatus",
					query: {
						id: item.orderid,
						paystatus: 3 //已签收
					}
				}).then(result => {
					this.GetList();
				})
			},
			GoComment(item) {
				sessionStorage.setItem("comment", JSON.stringify(item));
				this.$router.push({
					path: '/comment'
				})
			},
			Pay(item) {
				sessionStorage.setItem("orderid", item.children[0].order_id);
				window.location.href = "/pay/";
			},
			ReBuy(item) {
				this.$router.push({
					path: '/prodetail/' + item.children[0].goodid
				})
			},
			Delete(item) {
				$confirm({
					msg: "确认删除？",
					okcb: () => {
						this.$store.dispatch("GET", {
							url: "api/wx/?action=deleteorder&id=" + item.id
						}).then(result => {
							this.GetList();
							$Toast("删除成功")
						})
					}
				})
			},
			ShowInfo(item){
				console.log(item)
				sessionStorage.setItem("ordernum",item.orderid)
				this.$router.push("/orderdetail")
			}
		}
	}
</script>

<style scoped="scoped">
	.orderList {
		height: 100%;
		width: 100%;
	}
	
	.title {
		height: 38px;
		line-height: 38px;
		background: #f6fafe;
		display: flex;
		font-size: 18px;
		color: #8f8f8f;
		font-weight: bold;
	}
	
	.item {
		flex: 1;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.active {
		color: #5c91e2;
		border-bottom: 2px solid #26a1ff;
	}
	
	.ordernum {
		background: #f6fafe;
		padding: 10px 20px;
		border-bottom: 1px solid #dcdcdc;
		margin-top: 10px;
	}
	
	.orders {
		height: 88px;
		background: #f6fafe;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
	}
	
	.proimg {
		width: 80px;
	}
	
	.proimg>img {
		width: 90%;
		height: 90%;
	}
	
	.proInfo {
		flex: 1;
		margin-left: 15px;
	}
	
	.pros {
		height: 78px;
	}
	
	.counts {
		font-size: 12px;
		color: #a4a4a4;
		border-bottom: 1px solid #dcdcdc;
		padding-bottom: 10px;
	}
	
	.proname {
		padding: 8px 0;
		display: flex;
		height: 38px;
		overflow: hidden;
	}
	
	.name {
		flex: 1;
		font-size: 13px;
		color: #7b7b7b;
		overflow: hidden;
	}
	
	.price {
		font-size: 15px;
		color: #26a1ff;
		text-align: right;
	}
	
	.frees {
		text-align: right;
		font-size: 14px;
		color: #7b7b7b;
		padding-top: 10px;
	}
	
	.oneorder .counts:nth-last-child {
		border-bottom: 1px solid red
	}
	
	.buttons {
		display: flex;
		height: 35px;
		padding-top: 10px;
	}
	
	.wuli {
		margin-left: 10px;
		font-size: 12px;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #ddd;
		border-radius: 15px;
	}
	
	.shh {
		margin-left: 10px;
		font-size: 12px;
		color: #30a5ff;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #43aeff;
		border-radius: 15px;
	}
</style>