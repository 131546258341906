<template>
  <div class="cartbuy">

    <div class="orderAddress" v-for="item of Address" @click="GoAddress">
      <div style="width: 48px;" class="flexcenter">
        <img src="../../public/static/images/place.png" width="21" height="25" />
      </div>
      <div style="flex: 1;">
        <div class="addUser">
          <div style="flex: 1;">收货人：{{item.username}}</div>
          <div>{{item.phone}}</div>
        </div>
        <div class="address">收货地址：{{item.province+" "+item.city+" "+item.dist+" "+item.address}}</div>
      </div>
    </div>

    <div class="orderAddress" v-show="Address.length==0" @click="GoAddress">
      <div style="text-align: center; text-align: center; width: 100%; line-height: 75px;">
        还没有收货地址,点击创建
      </div>
    </div>

    <div class="proList" v-for="item of GoodList">
      <div class="proimg flexcenter">
        <img :src="item.headimg" width="85" height="85">
      </div>
      <div class="proInfo">
        <div class="name">{{item.title}}</div>
        <div class="pros">
          <div class="price">￥{{ parseFloat( item.price).toFixed(2)}}</div>
          <div class="count">&times;{{buycount}}</div>
        </div>
      </div>
    </div>

    <div v-show="groupid==0" style=" height: 40px;  margin-top: 14px; font-size: 12px; background: #f6fafe; line-height: 40px; padding: 0 20px;">
      当前积分：{{nowscore}}&nbsp;&nbsp;&nbsp;&nbsp;可用积分：{{canusescore>nowscore?nowscore:canusescore}}
      <div style="float: right;margin-top: -5px;">
        <switchbar :zt="switch_usescore" @switched="SwitchUsescore"></switchbar>
      </div>
    </div>

    <div style="background: #f6fafe; margin-top: 14px; padding-bottom: 20px;" v-if="CouponList.length>0">
      <div style="padding: 10px 20px 0 20px;" class="title">可用优惠券</div>
      <div v-for="(coupon,idx) in CouponList" style="text-align: right; padding: 5px 20px;" @click="chooseCoupon(coupon)">
        <img :src="'./static/images/coupon.png'" style="width: 20px; height: 20px; vertical-align: top;" />
        <span style="line-height: 20px;">{{coupon.coupon_name}}</span>
        <div style="width: 20px; height: 20px; display: inline-block; float: right; margin-left: 10px;">
          <img :src="'./static/images/'+(coupon.ckd?'checked':'nocheck')+'.png'" style="width: 100%; height: 100%; vertical-align: top;" />
        </div>
      </div>
    </div>

    <!--买家留言-->
    <div class="message">
      <div class="title">买家留言</div>
      <div class="content">
        <textarea type="text" v-model="words" style="width: 94%; border: 10px solid #fff; border-radius: 4px; height: 50px;"
          placeholder="给店小二说说您的需求"></textarea>
      </div>
    </div>
    <!--立即购买-->
    <div class="buynow">
      <div class="total">
        <span>合计：</span>
        <span class="money">￥{{paymoney}}&nbsp;
          <span style="color: gray;" v-show="expressprice>0">含运费￥{{expressprice.toFixed(2)}}</span>
          <span style="color: gray;" v-show="expressprice==0">包邮</span>
        </span>
      </div>
      <div class="button" @click="buy">立即购买</div>
    </div>
    <div v-show="youhui_money>0||dikou_score>0" style="position: fixed; bottom: 52px; height: 30px; line-height: 30px; width: 100%; text-align: center;">
      <span v-if="youhui_money>0">优惠 ￥{{youhui_money}}</span> <span v-if="dikou_score>0">积分抵扣：￥{{dikou_score}}</span>
    </div>
  </div>
</template>

<script>
  import switchbar from "../components/switchbar.vue"
  export default {
    components: {
      switchbar
    },

    data() {
      return {
        Address: [],
        GoodList: [],
        words: "",
        TotalMoney: 0,
        goodid: 0,
        buycount: 1,
        expressprice: 0,
        nowscore: 0,
        canusescore: 0,
        groupid: 0,
        scoreZhekou: 1,
        switch_usescore: false,
        paymoney: 0,
        youhui_money: 0,
        dikou_score: 0,
        uLevel: {
          configdesc: "",
          configvalue: 0,
          flag: 0,
          id: 1,
          totscore: 0
        },
        CouponList: []
      }
    },
    beforeMount() {

      if (sessionStorage.getItem("goodid")) {
        this.goodid = sessionStorage.getItem("goodid");
        this.buycount = sessionStorage.getItem("buycount");

      } else {
        this.$router.push("/prolist")
      }
      this.CalcMemberLevel();
    },
    methods: {

      calcLastMoney() {
        let m = 0;
        let buycount = this.buycount;
        m = parseFloat(this.GoodList[0].price) * buycount;
        let youhui_money = 0;
        //计算优惠券
        //2019-12-02修改优惠券 全部商品88折，不是第一件商品88折
        for (let item of this.CouponList) {
          if (item.ckd) {

            if (item.coupon_type == "dazhe") {

              youhui_money = m * (1 - parseFloat(item.coupon_value));
              m = m - youhui_money;

            }
            break;
          }
        }



        for (let item of this.CouponList) {
          if (item.ckd) {

            if (item.coupon_type == "manjian") {
				console.log("是满减"+item.coupon_money+"——"+m)
              if (parseFloat(item.coupon_money) <= m) { //满多少可以减
                m -= parseFloat(item.coupon_value)
                youhui_money = parseFloat(item.coupon_value)
              }else{
				  item.ckd = false;
				  $Toast("购买条件不足，无法使用此优惠券")
			  }
              break;
            }
          }
        }





        if (this.switch_usescore) {
          let socre = this.canusescore > this.nowscore ? this.nowscore : this.canusescore;
          m = parseFloat(m) - parseFloat(socre) + parseFloat(this.expressprice);
          this.dikou_score = socre;
          if (m < 0) {
            m = 0;
          }
        } else {
          this.dikou_score = 0;
          m += parseFloat(this.expressprice);
        }

        this.paymoney = m.toFixed(2);
        this.youhui_money = youhui_money.toFixed(2)
      },
      getCoupon() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=mycoupon"
        }).then(res => {
          let CouponList = [];
          for (let item of res.data.data.rows) {

            item.goods_id = "0," + item.goods_id + ",0"
            if (item.coupon_status == 1 && (item.all_can_use == 1 || item.goods_id.indexOf(this.goodid + ",") > 0)) {


              //检查是否在可用期限内
              if (item.coupon_usetime == 0) {
                let now = new Date().getTime();
                let start = new Date(item.coupon_startdate).getTime();
                let end = new Date(item.coupon_enddate).getTime();
                if (now >= start && now <= end) {
                  item.ckd = false;
                  CouponList.push(item)
                }
              } else {
                item.ckd = false;
                CouponList.push(item)
              }

            }
          }
          if (CouponList.length > 0) {
            CouponList[0].ckd = true;
          }
          this.CouponList = CouponList;

          console.log(this.CouponList)
          this.GetAddress();
        })
      },
      GetAddress() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=address"
        }).then(result => {
          if (sessionStorage.getItem("address")) {
            var adrid = sessionStorage.getItem("address");
            for (let item of result.data.data.rows) {
              if (item.id == adrid) {
                this.Address = [item];
                break;
              }
            }
          } else {
            if (result.data.data.rows.length > 0) {
              for (let item of result.data.data.rows) {
                if (item.isdefault > 0) {
                  this.Address = [item];
                  break;
                }
              }
              if (this.Address.length == 0) {
                this.Address = [result.data.data.rows[0]];
              }
			  

            } else {
              this.Address = [];
            }
          }
          this.CalcExpress();

        })
      },
      GoAddress() {
        this.$router.push("/address")
      },
      CalcExpress() {
        this.$store.dispatch("GET", {
          url: "/api/config/?action=express"
        }).then(res => {
          console.log(this.Address)
          if (this.Address.length > 0) {
            for (let item of res.data.data.rows) {
              if (item.configdesc.indexOf(this.Address[0].province) >= 0 || this.Address[0].province.indexOf(item.configdesc) >=
                0) {
                this.expressprice = parseFloat(item.configvalue);

                break
              }
            }
          }
          this.getproDetail();
        })
      },
      getproDetail() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=product&id=" + this.goodid
        }).then(result => {
          console.log(result.data.data.rows)
          this.GoodList = result.data.data.rows
          this.canusescore = this.GoodList[0].canusescore * this.buycount
          this.TotalMoney = parseFloat(this.GoodList[0].price) * parseInt(this.buycount)
          if (this.canusescore > this.TotalMoney) {
            this.canusescore = Math.ceil(this.TotalMoney)
          }
          this.canusescore += Math.ceil(this.expressprice);
          this.calcLastMoney();
        })
      },
      //立即购买
      buy() {
        let addressid = 0;
        if (this.Address.length > 0) {
          addressid = this.Address[0].id
        }
        let carts = "";
        for (let item of this.GoodList) {
          carts += item.cartid + ","
        }

        if (addressid == 0) {
          $Toast("请选择收货地址")
          return
        }
        if (carts == "") {
          $Toast("没有商品")
          return
        }
        let coupon_id = 0;
        for (let item of this.CouponList) {
          if (item.ckd) {
            coupon_id = item.coupon_id;
            break;
          }
        }

        this.$store.dispatch("POST", {
          url: "/api/wx/?action=buyone",
          query: {
            addrid: addressid,
            goods: this.goodid + "*" + this.buycount,
            words: this.words,
            usescore: this.switch_usescore ? 1 : 0,
            coupon_id: coupon_id,
            totalmoney: this.paymoney,
            dikou_score: this.dikou_score,
            expressprice: this.expressprice,
            youhui_money: this.youhui_money

          }
        }).then(result => {
          if (result.data.data.tmoney > 0) {
            sessionStorage.setItem("orderid", result.data.data.id);
            //this.$router.push('/buysuccess');
            window.location.href = "/pay/";
          } else {
            if (result.data.data && result.data.data.msg) {
              $Toast(result.data.data.msg);
            } else {
              $Toast("购买失败");
            }

          }

        })

      },

      CalcMemberLevel() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=ulvl"
        }).then(res => {

          this.nowscore = res.data.data.nowscore; //我的总分
          this.uLevel = (res.data.data)
          this.groupid = res.data.data.groupid
          if (this.uLevel.flag == 10 || this.uLevel.flag == 0) {
            //不参加折扣
          } else {
            //this.TotalMoney = this.TotalMoney * this.uLevel.flag / 10;
            this.scoreZhekou = this.uLevel.flag / 10; //积分折扣
          }


          this.getCoupon();
        })

      },
      SwitchUsescore(st) {
        this.switch_usescore = st;
        this.calcLastMoney()
      },
      chooseCoupon(coupon) {


        if (coupon.ckd) {
          coupon.ckd = false
        } else {
          for (let item of this.CouponList) {
            item.ckd = false;
          }
          coupon.ckd = true
        }

        this.calcLastMoney();
      }
    }
  }
</script>

<style scoped="scoped">
  .cartbuy {
    height: 100%;
    width: 100%;
  }

  .orderAddress {
    min-height: 77px;
    background: #f6fafe;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }

  .addUser {
    margin-top: 10px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    color: #7b7b7b;
    display: flex;
  }

  .address {
    line-height: 28px;
  }

  .proList {
    margin-top: 14px;
    height: 96px;
    background: #f6fafe;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  .proimg {
    width: 86px;
  }

  .proInfo {
    flex: 1;
    padding-left: 15px;
  }

  .name {
    margin-top: 12px;
    font-size: 13px;
    color: #7b7b7b;
  }

  .pros {
    margin-top: 12px;
    display: flex;
  }

  .price {
    flex: 1;
    font-size: 15px;
    color: #26a1ff;
  }

  .count {
    font-size: 12px;
    color: #a4a4a4;
  }

  .message {
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .title {
    font-size: 13px;
    color: #0c85e1;
  }

  .content {
    margin-top: 5px;
  }

  input {
    height: 25px;
    line-height: 25px;
    width: 100%;
    background: #f6fafe;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 12px;
    color: #b9b9b9;
  }

  .buynow {
    height: 52px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #f6fafe;
    border-top: 1px solid #85b1f7;
    display: flex;
  }

  .total {
    line-height: 52px;
    flex: 1;
    font-size: 15px;
    color: #979899;
    text-align: center;
    padding-right: 10px;
  }

  .money {
    color: #d92b2b;
  }

  .button {
    width: 120px;
    line-height: 52px;
    background: #26a1ff;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
</style>
