<template>
	<div>
		<div class="searchbar">
			<div style="height: 30px; padding: 10px;">
				<div class="searchbtn" @click="searchData">搜索</div>
				<input class="searcbox" v-model="keyword" placeholder="请输入医生或疾病名称" />

			</div>
		</div>
		<div style="height: 60px;"></div>
		<div class="dataitem" v-for="(item,idx) in DataList" @click="ziXun(item)">
			<div class="zjhead">
				<img :src="item.zj_headimg" />
			</div>
			<div class="zjinfo">
				<div class="zjname">
					{{item.zj_name}} {{item.zj_zhicheng}}
				</div>
				<div class="zj_zhicheng">
					{{item.zj_hospital}}
				</div>
				<div class="zj_desc">
					{{item.zj_desc}}
				</div>
			</div>

			<div class="btnzj">咨询</div>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword:"",
				DataList: []

			}
		},

		mounted() {
			if(sessionStorage.getItem("zxkeyword")){
				this.keyword = sessionStorage.getItem("zxkeyword")
			}
			this.getList();
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjlisth5&pagesize=500&keyword="+encodeURIComponent(this.keyword)
				}).then(result => {
					for (let item of result.data.rows.rows) {
						item.zj_desc = decodeURIComponent(item.zj_desc)
					}
					this.DataList = result.data.rows.rows;
					$("#loading").hide()
				})
			},
			searchData(){
				sessionStorage.setItem("zxkeyword",this.keyword)
				this.getList()
			},
			ziXun(item){
				this.$router.push("/zhuanjia/"+item.id)
			}
		}
	}
</script>

<style scoped="scoped">
	.dataitem {
		padding: 15px 15px 0 15px;
		position: relative;
	}

	.zjhead {
		width: 100px;
		height: 100px;
		float: left;
	}

	.zjhead img {
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}

	.zjinfo {
		margin-left: 110px;
		min-height: 100px;
	}

	.zjname {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		color: #000;
		font-weight: bold;
	}

	.zj_zhicheng {
		color: #474747;
		font-size: 13px;
		padding-top: 6px;
	}

	.zj_desc {
		padding-top: 6px;
		font-size: 12px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;



	}

	.btnzj {
		width: 60px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #0C85E1;
		color: #FFFFFF;
		position: absolute;
		right: 10px;
		top: 38px;
		border-radius: 4px;
		font-size: 12px;
	}

	.searchbar {
		position: fixed;
		height: 50px;
		top: 0;
		width: 100%;
		z-index: 1;
		background: rgba(255, 255, 255, 0.8);
	}

	.searcbox {
		width: 70%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		padding-left: 30px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586240157273748023.png);
		background-size: auto 80%;
		background-repeat: no-repeat;
		background-position: left center;
	}

	.searchbtn {
		height: 30px;
		width: 50px;
		float: right;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 4px;
	}
</style>
