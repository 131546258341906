<template>
	<div>
		<div v-for="zj in DataInfo" style="padding: 15px;">
			<div class="zjhead">
				<img :src="zj.zj_headimg" />
			</div>
			<div class="zjinfo">
				<div style="line-height: 50px; color: #000000;">
					{{zj.zj_name}} {{zj.zj_zhicheng}}
				</div>
				<div class="zj_zhicheng">
					{{zj.zj_hospital}}
				</div>

				<div class="zj_zhicheng" style="text-align: right;font-size: 12px; padding-top: 30px;">
					已服务用户：{{zj.zj_real_count}}人
				</div>
			</div>

		</div>
		<div style="border-top: 1px solid #f6f6f6;border-bottom: 1px solid #f6f6f6; text-align: center; line-height: 50px;">
			<div style="width: 50%; height: 50px; float: left;">总收益：<span style="color: orangered;">¥{{historymoney}}</span></div>
			<div style="width: 50%; height: 50px; margin-left: 50%;">
				可提现：<span style="color:#007AFF;">¥{{mymoney}}</span>
				<span style="color:#007AFF; padding-left: 10px;" @click="tiXian">提现</span>
			</div>
		</div>
		<div style="height: 20px;">

		</div>
		<div @click="goPage('/zxzjreplylist')" style="border-top: 1px solid #f6f6f6;border-bottom: 1px solid #f6f6f6; line-height: 50px;">
			<div style="padding: 0 20px; position: relative;">
				咨询列表
				<div class="rightarrow"></div>
			</div>
		</div>
		<div @click="goPage('/zxshouyi')" style="border-bottom: 1px solid #f6f6f6; line-height: 50px;">
			<div style="padding: 0 20px; position: relative;">
				收益历史
				<div class="rightarrow"></div>
			</div>
		</div>
		<div @click="goPage('/zxtixian')" style="border-bottom: 1px solid #f6f6f6; line-height: 50px;">
			<div style="padding: 0 20px; position: relative;">
				提现历史
				<div class="rightarrow"></div>
			</div>
		</div>



		<div class="mask" id="txmask" style="background: rgba(0,0,0,0.6); display: none;">
			<div style="height: 10%;"></div>
			<div class="txbox">
				<div>请输入提现金额（最高提现 ¥{{mymoney}}）：</div>
				<div style="text-align: center; padding: 10px 0;">
					<input type="number" class="moneybox" v-model="txmoney" />
				</div>
				<div style="font-size: 12px; color: #888888; padding-top: 10px;">
					提示：实际到账金额 ¥{{txmoney|lastmoney}}
				</div>
				<div style="padding-top: 20px;">
					<div class="btncancel" @click="cancelTxMask">取消</div>
					<div class="btnok" @click="confirmTx">确定</div>
				</div>
				<div style="font-size: 12px; color: #888888; padding-top: 10px;">
					提示：进行提现操作，腾讯收取1%手续费，从提现金额中扣除！
				</div>

			</div>

		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				DataInfo: [],
				chance: -1,
				mymoney: 0,
				txmoney: 0,
				historymoney:0,
				taped:false
			}
		},
		mounted() {
			this.getList();

		},
		filters: {
			lastmoney(e) {
				return Math.round(e * 0.99 * 100) / 100
			}
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjone&id=1"
				}).then(res => {
					for (let item of res.data.rows) {
						item.zj_vt_count = parseInt(item.zj_vt_count)
						item.zj_real_count = parseInt(item.zj_real_count)
						item.zj_content = decodeURIComponent(item.zj_content)
						item.zj_desc = decodeURIComponent(item.zj_desc)
						this.historymoney = item.zj_historymoney
						this.mymoney = parseFloat(item.zj_nowmoney ? item.zj_nowmoney : 0)
						this.txmoney =  item.zj_nowmoney
					}
					this.DataInfo = res.data.rows
					$("#loading").hide()
				})
			},


			goPage(page) {
				this.$router.push(page)
			},
			tiXian() {
				
				if (this.mymoney > 0) {
					this.showTxMask()
				} else {
					$alert({
						msg: "您的余额不足，无法提现！"
					})
				}
			},
			cancelTxMask() {
				$("#txmask").animate({
					opacity: 0
				}, 300, "linear", function() {
					$("#txmask").hide()
				})
			},
			showTxMask() {
				$("#txmask").css("opacity", 0).show().animate({
					opacity: 1
				}, 300, "linear", function() {

				})
			},
			confirmTx(){
				if(!this.taped){
					this.taped = true;
					setTimeout(()=>{
						this.taped = false
					},2000)
					$("#loading").show()
					this.$store.dispatch("GET", {
						url: "/api/zixun/?action=tixian&money="+this.txmoney
					}).then(res => {
						$("#loading").hide()
						if(res.data.msg=="success"){
							$alert({
								msg: "提现成功，请至微信钱包查收"
							})
							this.getList()
						}else{
							alert(res.data.msg)
						}
						
						
					})
					
					
				}else{
					return
				}
				this.cancelTxMask()
			}
		}
	}
</script>

<style scoped="scoped">
	.zjhead {
		float: left;
		width: 120px;
		height: 120px;
	}

	.zjhead img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.zjinfo {
		margin-left: 130px;
		min-height: 120px;
	}

	.zjname {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		color: #000;
		font-weight: bold;
	}

	.zj_zhicheng {
		color: #474747;
		font-size: 13px;
		padding-top: 6px;
	}

	.rightarrow {
		position: absolute;
		width: 20px;
		height: 20px;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578299336326972.png);
		background-size: cover;
		right: 10px;
		top: 15px;
	}

	.txbox {
		width: 80%;
		padding: 30px 20px;
		background: #FFFFFF;
		margin: 0 auto;
		border-radius: 4px;
	}

	.moneybox {
		-webkit-appearance: none;
		text-align: center;
		width: 70%;
		height: 40px;
		border: 1px solid #F6F6F6;
		outline: none;
		text-align: center;
		font-size: 20px;
		color: orangered;
	}

	.btncancel {
		width: 40%;
		height: 40px;
		text-align: center;
		background: #CCCCCC;
		float: left;
		line-height: 40px;
		border-radius: 6px;
	}

	.btnok {
		width: 50%;
		height: 40px;
		text-align: center;
		background: #007AFF;
		color: #FFFFFF;
		margin-left: 50%;
		line-height: 40px;
		border-radius: 6px;
	}
</style>
