<template>
	<div class="wrapper">
		<div class="content">
			<router-view></router-view>
		</div>
		<v-footer></v-footer>
	</div>
</template>

<script>
	import vFooter from '../components/footer.vue'
	export default {
		data() {
			return {}
		},
		created() {

		},
		mounted() {

		},
		methods: {
		},
		components: {
         vFooter
        }
	}
</script>

<style scoped="scoped">
.content{
	
	padding-bottom: 60px;
}
	
</style>